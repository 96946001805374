<template>

<com-list class="products" api="products" :block="block" v-on:loaded="onListLoaded">

	<router-link :to="$link($route.path + '/' + item.url)" class="products-item" v-for="(item, index) in list" :key="index">

		<div class="products-item-image" v-bgimage="item.image"></div>

		<div class="products-item-name">{{ item.name }}</div>
		<div class="products-item-price">{{ item.currency }}{{ item.price }}</div>

	</router-link>

</com-list>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock],

	components: {
		'com-list': () => import('./common/List')
	}

}

</script>

<style scoped>

.products-item {
	width: calc(33.3% - 13px);
	margin-right: 20px;
}

.is-device .products-item {
	width: calc(50% - 10px);
}

.is-desktop .products-item:nth-child(3n) {
	margin-right: 0px;
}

.is-device .products-item:nth-child(2n) {
	margin-right: 0px;
}

.products-item-image {
	width: 245px;
	border-radius: 50%;
	border: 10px solid #b7b7b7;
	height: 245px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	margin: 0px auto;
}

.is-device .products-item-image {
	width: 180px;
	height: 180px;
}

.is-mobile .products-item-image {
	width: 140px;
	height: 140px;
	border-width: 6px;
}

.products-item-name {
	margin-top: 20px;
	color: #1a234c;
	font-size: 24px;
	line-height: 30px;
}

.is-device .products-item-name {
	font-size: 20px;
}

.is-mobile .products-item-name {
	font-size: 16px;
	line-height: 20px;
}

.products-item-price {
	margin-top: 4px;
	color: #1a234c;
	font-size: 24px;
	line-height: 24px;
	font-weight: bold;
}

</style>
